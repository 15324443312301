@import '../../styles/variables';

.contact {
  min-height: 100vh;
  padding: 6rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .gradient-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    height: 700px;
    z-index: 1;
    
    .gradient-background {
      width: 100%;
      height: 100%;
      background: radial-gradient(
        circle at center,
        rgba(0, 180, 216, 0.05) 0%,
        rgba(18, 18, 18, 0.2) 40%,
        rgba(18, 18, 18, 0.8) 80%,
        rgba(18, 18, 18, 0.95) 100%
      );
      backdrop-filter: blur(10px);
      border-radius: 16px;
      border: 1px solid rgba(0, 180, 216, 0.1);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-size: 2.5rem;
      margin-bottom: 3rem;
      font-weight: 700;
      background: linear-gradient(45deg, #fff, $secondary-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin: 2rem auto;
    max-width: 900px;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__info {
    text-align: left;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    border: 1px solid rgba(0, 180, 216, 0.1);

    h3 {
      font-size: 1.8rem;
      color: $secondary-color;
      margin-bottom: 1rem;
    }

    p {
      color: $text-color;
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 2rem;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: $text-color;
      text-decoration: none;
      font-size: 1.1rem;
      padding: 0.8rem;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.05);
      transition: all 0.3s ease;

      &:hover {
        background: rgba(0, 180, 216, 0.1);
        color: $secondary-color;
      }

      svg {
        font-size: 1.4rem;
      }
    }
  }

  &__form {
    text-align: left;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    border: 1px solid rgba(0, 180, 216, 0.1);

    .form__group {
      margin-bottom: 1.5rem;

      input,
      textarea {
        width: 100%;
        padding: 1rem;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(0, 180, 216, 0.2);
        border-radius: 8px;
        color: $text-color;
        font-size: 1rem;
        transition: all 0.3s ease;

        &:focus {
          outline: none;
          border-color: $secondary-color;
          background: rgba(255, 255, 255, 0.1);
        }
      }

      textarea {
        min-height: 150px;
        resize: vertical;
      }
    }

    button {
      width: 100%;
      padding: 1rem;
      background: $secondary-color;
      border: none;
      border-radius: 8px;
      color: #fff;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    .form__status {
      margin-top: 1rem;
      padding: 0.8rem;
      border-radius: 8px;
      text-align: center;

      &.success {
        background: rgba(46, 213, 115, 0.1);
        color: #2ed573;
      }

      &.error {
        background: rgba(255, 71, 87, 0.1);
        color: #ff4757;
      }
    }
  }
} 