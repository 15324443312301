@import './styles/variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none !important;
}

body {
  font-family: 'Inter', sans-serif;
  background: $primary-color;
  color: $text-color;
}

.app {
  min-height: 100vh;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  &__content {
    text-align: center;
  }

  &__circle {
    width: 50px;
    height: 50px;
    border: 3px solid $secondary-color;
    border-top-color: transparent;
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  h2 {
    color: $text-color;
    font-size: 1.5rem;
  }
}

.cursor {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 180, 216, 0.8);
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  transition: all 0.1s ease;
  transition-property: width, height, border;
}

.cursor-dot {
  width: 4px;
  height: 4px;
  background: #00b4d8;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999 !important;
}

.cursor-dot-outline {
  z-index: 9999 !important;
}

.cursor-grow {
  transform: scale(2);
  background: rgba(0, 180, 216, 0.1);
} 