// Colors
$primary-color: #1a1a1a;
$secondary-color: #00b4d8;
$accent-color: #4cc9f0;
$text-color: #e0e0e0;
$background-color: #121212;
$gradient-dark: #1a1a1a;

// Fonts
$font-primary: 'Space Grotesk', sans-serif;
$font-secondary: 'Inter', sans-serif;

// Breakpoints
$mobile: 576px;
$tablet: 768px;
$desktop: 1024px;

// Typography
$font-primary: 'Inter', sans-serif;
$font-secondary: 'Poppins', sans-serif; 