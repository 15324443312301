@import '../../styles/variables';

.about {
  min-height: 100vh;
  padding: 6rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .gradient-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    height: 800px;
    z-index: 1;
    
    .gradient-background {
      width: 100%;
      height: 100%;
      background: radial-gradient(
        circle at center,
        rgba(0, 180, 216, 0.05) 0%,
        rgba(18, 18, 18, 0.2) 40%,
        rgba(18, 18, 18, 0.8) 80%,
        rgba(18, 18, 18, 0.95) 100%
      );
      backdrop-filter: blur(10px);
      border-radius: 16px;
      border: 1px solid rgba(0, 180, 216, 0.1);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 1100px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 0 20px;

    h2 {
      font-size: 2.5rem;
      color: $text-color;
      margin-bottom: 2rem;
      font-weight: 700;
      background: linear-gradient(45deg, #fff, $secondary-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__text {
    max-width: 800px;
    margin: 0 auto 4rem;
    
    p {
      color: $text-color;
      font-size: 1.2rem;
      line-height: 1.8;
      opacity: 0.9;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin: 4rem auto;
    max-width: 900px;

    .card {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(0, 180, 216, 0.1);
      border-radius: 16px;
      padding: 1.8rem;
      transition: all 0.3s ease;
      backdrop-filter: blur(5px);

      &__icon {
        font-size: 2.2rem;
        color: $secondary-color;
        margin-bottom: 0.8rem;
      }

      h3 {
        font-size: 1.3rem;
        margin-bottom: 0.8rem;
        color: $text-color;
      }

      p {
        font-size: 0.95rem;
        color: $text-color;
        opacity: 0.8;
        line-height: 1.6;
      }
    }
  }

  &__stats {
    display: flex;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;

    .stat {
      text-align: center;

      h3 {
        font-size: 2.5rem;
        color: $secondary-color;
        margin-bottom: 0.5rem;
      }

      p {
        color: $text-color;
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 968px) {
    &__cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 640px) {
    &__cards {
      grid-template-columns: 1fr;
      max-width: 400px;
    }
  }

  @media (max-width: $mobile) {
    padding: 4rem 1rem;

    &__content h2 {
      font-size: 2rem;
    }

    &__text p {
      font-size: 1rem;
    }

    &__stats {
      gap: 2rem;
    }
  }
} 