@import '../../styles/variables';

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 1rem 2rem;
  transition: all 0.3s ease;
  background: transparent;

  &.scrolled {
    background: rgba($primary-color, 0.95);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0.8rem 2rem;
  }

  &__content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    font-size: 1.8rem;
    font-weight: 700;
    color: $secondary-color;
    cursor: pointer;
  }

  &__links {
    display: flex;
    gap: 2rem;

    @media (max-width: $mobile) {
      display: none;
    }

    button {
      background: none;
      border: none;
      color: $text-color;
      font-size: 1rem;
      cursor: pointer;
      padding: 0.5rem 1rem;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: $secondary-color;
        transition: width 0.3s ease;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }

  &__mobile-trigger {
    display: none;
    cursor: pointer;

    @media (max-width: $mobile) {
      display: block;
    }

    .hamburger {
      width: 24px;
      height: 20px;
      position: relative;
      
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $text-color;
        transition: all 0.3s ease;

        &:first-child {
          top: 0;
        }

        &:nth-child(2) {
          top: 9px;
        }

        &:last-child {
          bottom: 0;
        }
      }

      &.open {
        span {
          &:first-child {
            transform: rotate(45deg);
            top: 9px;
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            transform: rotate(-45deg);
            bottom: 9px;
          }
        }
      }
    }
  }

  &__mobile-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: rgba($primary-color, 0.95);
    backdrop-filter: blur(10px);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      background: none;
      border: none;
      color: $text-color;
      font-size: 1rem;
      padding: 0.8rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: rgba($secondary-color, 0.1);
        color: $secondary-color;
      }
    }
  }
} 