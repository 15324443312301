// ui/src/components/Projects/Projects.scss

@import '../../styles/variables'; // Import the variables file

.projects {
  min-height: 100vh;
  padding: 6rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .gradient-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    height: 1200px;
    z-index: 1;
    
    .gradient-background {
      width: 100%;
      height: 100%;
      background: radial-gradient(
        circle at center,
        rgba(0, 180, 216, 0.05) 0%,
        rgba(18, 18, 18, 0.2) 40%,
        rgba(18, 18, 18, 0.8) 80%,
        rgba(18, 18, 18, 0.95) 100%
      );
      backdrop-filter: blur(10px);
      border-radius: 16px;
      border: 1px solid rgba(0, 180, 216, 0.1);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-size: 2.5rem;
      margin-bottom: 3rem;
      font-weight: 700;
      background: linear-gradient(45deg, #fff, $secondary-color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
  }

  &__item {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(0, 180, 216, 0.1);
    border-radius: 16px;
    padding: 1.8rem;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &-preview {
      font-size: 0.95rem;
      line-height: 1.6;
      color: $text-color;
      opacity: 0.8;
      margin-bottom: 1rem;
    }

    &-overlay {
      position: absolute;
      inset: 0;
      background: rgba(0, 180, 216, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.3s ease;

      span {
        color: $secondary-color;
        font-weight: 500;
        background: rgba(0, 0, 0, 0.7);
        padding: 0.5rem 1rem;
        border-radius: 4px;
      }
    }

    &:hover {
      .projects__item-overlay {
        opacity: 1;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      .icon-group {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon {
          font-size: 1.5rem;
          color: $secondary-color;
        }

        .emoji {
          font-size: 1.5rem;
        }
      }

      .read-more {
        font-size: 0.9rem;
        color: $secondary-color;
        opacity: 0;
        transition: opacity 0.3s ease;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        svg {
          font-size: 1rem;
        }
      }
    }

    &:hover {
      .read-more {
        opacity: 1;
      }
    }

    h3 {
      font-size: 1.3rem;
      color: $secondary-color;
      margin-bottom: 1rem;
    }

    &-description {
      position: relative;
      max-height: 100px;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

      p {
        font-size: 0.95rem;
        line-height: 1.6;
        color: $text-color;
        opacity: 0.9;
        margin: 0;
      }
    }
  }

  &__overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(8px);
    z-index: 100;
  }

  &__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    background: rgba(26, 26, 26, 0.95);
    border: 1px solid rgba(0, 180, 216, 0.2);
    border-radius: 16px;
    padding: 2rem;
    width: 90%;
    max-width: 800px;
    max-height: 80vh;
    overflow: hidden;
    z-index: 101;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  
    .modal-content {
      height: calc(80vh - 4rem);
      overflow-y: auto;
      padding-right: 1rem;
      
      &::-webkit-scrollbar {
        width: 8px;
      }
  
      &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: $secondary-color;
        border-radius: 4px;
      }
    }
  
    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: none;
      border: none;
      color: $text-color;
      font-size: 1.2rem;
      cursor: pointer;
      z-index: 2;
      transition: color 0.3s ease;
  
      &:hover {
        color: $secondary-color;
      }
    }
  
    .modal-header {
      position: relative;
      z-index: 1;
    }
  
    p {
      margin-bottom: 1rem;
      padding-right: 1rem;
    }
  }
  

  @media (prefers-reduced-motion: reduce) {
    &__item {
      transition: none;

      &-description {
        transition: none;
      }
    }
  }

  @media (max-width: $tablet) {
    &__list {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }

  @media (max-width: $mobile) {
    padding: 4rem 1rem;

    &__content h2 {
      font-size: 2rem;
    }

    &__item {
      padding: 1.5rem;

      &-header {
        .icon, .emoji {
          font-size: 1.5rem;
        }
      }

      &::after {
        display: none;
      }

      &-description {
        max-height: none;
        overflow: visible;
      }
    }

    &__modal {
      width: 95%;
      max-height: 75vh;
      padding: 1.5rem;
    }
  }
}