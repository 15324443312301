@import '../../styles/_variables.scss';

.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  
  .particles {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .gradient-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 900px;
    height: 540px;
    z-index: 1;
    
    .gradient-background {
      width: 100%;
      height: 100%;
      background: radial-gradient(
        circle at center,
        rgba(0, 180, 216, 0.05) 0%,
        rgba(18, 18, 18, 0.2) 40%,
        rgba(18, 18, 18, 0.8) 80%,
        rgba(18, 18, 18, 0.95) 100%
      );
      backdrop-filter: blur(10px);
      border-radius: 16px;
      border: 1px solid rgba(0, 180, 216, 0.1);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;

    .hero__profile {
      width: 160px;
      height: 160px;
      margin: 0 auto 1.8rem;
      border-radius: 50%;
      border: 3px solid #00b4d8;
      padding: 5px;
      overflow: hidden;
      background: #121212;
      
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .glow-on-hover {
      transition: all 0.3s ease;
      
      &:hover {
        text-shadow: 0 0 10px rgba(0, 180, 216, 0.5),
                    0 0 20px rgba(0, 180, 216, 0.3),
                    0 0 30px rgba(0, 180, 216, 0.2);
        color: #00b4d8;
      }
    }

    h1 {
      font-size: 3.2rem;
      margin-bottom: 0.8rem;
      color: white;
      font-weight: 700;
      cursor: default;
    }

    h2 {
      font-size: 1.6rem;
      color: #00b4d8;
      margin-bottom: 1.8rem;
      cursor: default;
    }

    .social-links {
      display: flex;
      justify-content: center;
      gap: 1.8rem;
      margin-top: 1.8rem;

      a {
        color: white;
        font-size: 1.6rem;
        transition: all 0.3s ease;
        padding: 0.45rem;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        
        &:hover {
          background: rgba(0, 180, 216, 0.1);
        }
      }
    }
  }
}